import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Commande} from '../../Models/commande';
import {Plat} from '../../Models/plat';
import {Offre} from '../../Models/offre';
import {RestaurantInfoService} from '../../Services/RestaurantInfoService/restaurant-info.service';
import {RestaurantInfo} from '../../Models/restaurant-info';
import {CustomOffres} from '../../Models/custom-offres';
import {CommandesService} from '../../Services/Commandes/commandes.service';
import {toNumbers} from '@angular/compiler-cli/src/diagnostics/typescript_version';
import { RasterObj, StarPRNT } from '@ionic-native/star-prnt';
import { Toast } from 'bootstrap';
import { ToastController } from '@ionic/angular';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { PrinterService } from "../../Services/PrinterService/printer.service";
import { ReceiptService } from '../../Services/PrinterService/receipt.service';
import { AlertService } from '../../Services/PrinterService/alert.service';
import { stringify } from 'querystring';
import { Line } from 'chartist';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-detaille-commande',
  templateUrl: './detaille-commande.component.html',
  styleUrls: ['./detaille-commande.component.css']
})
export class DetailleCommandeComponent implements OnInit {
  defaultPrinter:any;
  prix_tot: number;

  constructor(private matdialogref: MatDialogRef<DetailleCommandeComponent>,
              private rs: RestaurantInfoService, @Inject(MAT_DIALOG_DATA) public data: any,
               private cs: CommandesService, public toastController: ToastController,
               public navCtrl: NavController, public alertCtrl: AlertController,
                private printerService:PrinterService, public modalCtrl: ModalController,
                private alertService: AlertService,  private receiptService: ReceiptService,
                public datepipe: DatePipe

              ) {
                this.ionViewDidEnter();
  }
  
  calculPrixTotal(cmd : Commande){
    this.prix_tot = 0;
    cmd.requested_plat.forEach(rp => {
      this.prix_tot = this.prix_tot + (rp.prix * rp.quantity);
      rp.customs.forEach(custom => {
        this.prix_tot = this.prix_tot + (custom.prix * rp.quantity);
      });
    });
    
  }

  printRasterReceipt(){
    if(this.defaultPrinter){
      let rasterObj:RasterObj = this.receipeEdit();
        this.printerService.printRasterReceipt(this.defaultPrinter.portName, this.defaultPrinter.emulation, rasterObj)
          .then(result => {
            this.presentToast("Imprimer")})
          .catch(error => {
            this.alertService.createAlert(error) })
     
      }else{
        this.presentToast("Aucune imprimante séléectionner");
      }
  }

  ionViewDidEnter() {
    this.printerService.getDefaultPrinter()
    .then(printer => {
      console.log(printer);
      this.defaultPrinter = printer});
  }

  restau: RestaurantInfo;
  listeplats: Plat[] = this.data.requested_plat;
  listeOffres: CustomOffres[] = this.data.custom_offres;
  listePlatsOffres: Plat[] = [];
  panelOpenState = true;
  status = false;
  ip = '';
  id: number;

  
  async presentToast(res) {
    const toast = await this.toastController.create({
      message: '->'+res,
      duration: 2000
    });
    toast.present();
  }
  
  ngOnInit(): void {
    this.calculPrixTotal(this.data)
    if (this.data.id === undefined) {
      this.cs.getId(this.data.commande_id).subscribe((d: any) => {
        this.id = d;
      });
    } else {
      this.id = this.data.id;
    }
    this.rs.all().subscribe((r: RestaurantInfo[]) => {
      this.restau = r[0];
    });
    if (this.listeOffres) {
      this.listeOffres.forEach(o => {
        o.requested_plats.forEach(p => {
          this.listePlatsOffres.push(p);
        });
      });
    }
  }

  close() {
    this.matdialogref.close();
  }

  receipeEdit(){
    let textReceip : String;
    console.log(this.data);
    textReceip = 
    "Client: "+this.data.user.nom +" "+ this.data.user.prenom+"\n" +
    "\n" +
    "Date:"+this.datepipe.transform(this.data.created_at,'dd/MM/yy, à HH:mm') +"          \n" +
    "Standard:  A emporter"+ 
    "\n---------------------------\n";
    this.data.requested_plat.forEach(produit => {
      textReceip = "\n"+textReceip + "->" +produit.quantity+" x "+produit.nom+": "+produit.prix+"€ \n"
      produit.customs.forEach(modificateur => {
        modificateur.ingredients.forEach(ing => {
          if (modificateur.prix == 0){
           textReceip = textReceip +" * "+ ing.nom+"\n";
          }else{
            textReceip = textReceip +" * "+ ing.nom+": +"+modificateur.prix+"€ \n"  ;
          }
         })
      })
      textReceip = textReceip+"\n";
    });
    console.log(textReceip);
    let threeInchRasterImage: RasterObj= {
        text: textReceip+
        "\n---------------------------\n" +
        "Total                 "+this.prix_tot+"€\n",
        paperWidth:576,
        fontSize:35,  
        cutReceipt:true,
        openCashDrawer:false
      };
      return threeInchRasterImage ;
}

  /*print() {
    this.printService.init()
      .setBold(true)
      .writeLine(this.restau.nom_restaurant)
      .setBold(false)
      .writeLine(this.restau.address)
      .writeLine('Tel: ' + this.restau.tel)
      .writeLine('-------------------------------------------')
      .writeLine('------------Adresse livraison--------------')
      .writeLine(this.data.Commande.user.nom + ' ' + this.data.Commande.user.prenom)
      .writeLine(this.data.Commande.livraison_address)
      .writeLine(this.data.Commande.livraison ? this.data.Commande.livraison_address : 'Click and Collect')
      .writeLine(this.data.Commande.user.numero_de_telephone.toString())
      .writeLine('-----------------------------------')

      .feed(4)
      .cut('full')
      .flush();
  }*/

}
