import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CommandesService} from '../../Services/Commandes/commandes.service';
import {Commande} from '../../Models/commande';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DetailleCommandeComponent} from '../detaille-commande/detaille-commande.component';
import {MatSort} from '@angular/material/sort';
import {ConfirmationBannerComponent} from '../confirmation-banner/confirmation-banner.component';
import {FirebaseService} from '../../Services/Firebase/firebase.service';
import {DatePipe} from '@angular/common';
import { RasterObj, StarPRNT } from '@ionic-native/star-prnt';

import { AlertController, ToastController } from '@ionic/angular';
import { PrinterService } from '../../Services/PrinterService/printer.service';
import { AlertService } from '../../Services/PrinterService/alert.service';
import { threadId } from 'worker_threads';


@Component({
  selector: 'app-commandes-en-atente',
  templateUrl: './commandes-en-atente.component.html',
  styleUrls: ['./commandes-en-atente.component.css']
})
export class CommandesEnAtenteComponent implements OnInit {
  defaultPrinter:any;
  prix_tot: number;
  audio = new Audio();
  firstAccess = false;
  constructor(private commandService: CommandesService, private datepipe: DatePipe,
    private alertService: AlertService, private printerService:PrinterService,
    private dialog: MatDialog, private fb: FirebaseService,public toastController: ToastController) {
      this.ionViewDidEnter();
     }
  realTimeCommandes = [];
  listecommandes: Commande[] = [];
  displayedColumns: string[] = ['nom', 'numero_de_telephone', 'détailles de commande', 'prix_total', 'validate', 'cancel'];
  dataSource = new MatTableDataSource(this.listecommandes);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
      this. fb.commandes().subscribe((data: any[]) => {
        this.realTimeCommandes = data;
        
        if (this.realTimeCommandes.length > 0 ){
           
          this.audio.src = '/assets/1.mp3';
          this.audio.load();
          this.audio.play();
        
        }
        this.firstAccess = true;
        // get all commandes
        this.commandService.getAll().subscribe((d: Commande[]) => {
          this.listecommandes = Object.assign(this.listecommandes, d);
          // filter commandes non vues et les affecter au datasource.data
          this.dataSource.data = this.listecommandes.filter(c => c.status === 'non_vue' && c.date_paiement == null);
          this.dataSource.paginator = this.paginator;
  
  
  
        });
      });

    
  }

  ionViewDidEnter() {
    this.printerService.getDefaultPrinter()
    .then(printer => {
      console.log(printer);
      this.defaultPrinter = printer});
  }

  detaillesCommande(commande: Commande) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '75%';
    dialogConfig.height = '95%';
    dialogConfig.data = commande;
 
    this.dialog.open(DetailleCommandeComponent, dialogConfig);
  }
  async presentToast(res) {
    const toast = await this.toastController.create({
      message: '->'+res,
      duration: 2000
    });
    toast.present();
  }

 



  validate(cmd: Commande) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '50%';
    dialogConfig.height = '30%';

    const dialogref = this.dialog.open(ConfirmationBannerComponent, dialogConfig);
    dialogref.afterClosed().subscribe((retour: boolean) => {
      if (retour === true) {cmd.status = 'en_cours';
        this.audio.pause();
        this.printRasterReceipt(cmd);
  
      
      cmd.date_paiement = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      this.commandService.updateCommand(cmd).subscribe(data => {
        this.dataSource.data.splice(this.dataSource.data.indexOf(cmd), 1);
        this.dataSource.paginator = this.paginator;
        this.realTimeCommandes.forEach(c =>  {
          if (c.idCommande == cmd.commande_id) {
            c.status = 'en_cours';
            this.fb.update(c).then(co => {
            });
          }
        });
      });
      }
    });
  }

  cancel(cmd: Commande) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '50%';
    dialogConfig.height = '30%';
    const dialogref = this.dialog.open(ConfirmationBannerComponent, dialogConfig);
    dialogref.afterClosed().subscribe((retour: boolean) => {
      if (retour === true) {
        cmd.status = 'annulee';
        this.commandService.updateCommand(cmd).subscribe(data => {
          this.realTimeCommandes.forEach(c =>  {
            if (c.idCommande == cmd.commande_id) {
              c.status = 'annulee';
              this.fb.update(c).then(co => {
              });
            }
          });
          this.dataSource.data.splice(this.dataSource.data.indexOf(cmd), 1);
          this.dataSource.paginator = this.paginator;
        });
      }
    });
  }

  // tslint:disable-next-line:typedef
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase(); 
  }

   printRasterReceipt(data : any){
    //console.log(rasterObj);
    if(this.defaultPrinter){
      let rasterObj:RasterObj = this.receipeEdit(data);

        this.printerService.printRasterReceipt(this.defaultPrinter.portName, this.defaultPrinter.emulation, rasterObj)
          .then(result => {
            this.printerService.printRasterReceipt(this.defaultPrinter.portName, this.defaultPrinter.emulation, rasterObj)
            .then(result => {
              this.presentToast("Imprimer")})
            .catch(error => {
              this.alertService.createAlert(error);
              console.log(error);
            })  
          
          })
          .catch(error => {
            this.alertService.createAlert(error);
            console.log(error);
          })
         
     
      }else{
        this.presentToast("Aucune imprimante séléectionner");
      }
  }
  calculPrixTotal(cmd : Commande){
    this.prix_tot = 0;
    cmd.requested_plat.forEach(rp => {
      this.prix_tot = this.prix_tot + (rp.prix * rp.quantity);
      rp.customs.forEach(custom => {
        this.prix_tot = this.prix_tot + (custom.prix * rp.quantity);
      });
    });
    
  }
  receipeEdit(data){
    this.calculPrixTotal(data);
      let textReceip : String;
      console.log(data);
      textReceip = 
      "Client: "+data.user.nom +" "+ data.user.prenom+"\n" +
      "\n" +
      "Date:"+this.datepipe.transform(data.created_at,'dd/MM/yy, à HH:mm') +"          \n" +
      "Standard:  A emporter"+ 
      "\n---------------------------\n";
      data.requested_plat.forEach(produit => {
        textReceip = "\n"+textReceip + "->" +produit.quantity+" x "+produit.nom+": "+produit.prix+"€ \n"
        produit.customs.forEach(modificateur => {
          modificateur.ingredients.forEach(ing => {
            if (modificateur.prix == 0){
             textReceip = textReceip +" * "+ ing.nom+"\n";
            }else{
              textReceip = textReceip +" * "+ ing.nom+": +"+modificateur.prix+"€ \n"  ;
            }
           })
        })
        textReceip = textReceip+"\n";
      });

      console.log(textReceip);
      let threeInchRasterImage: RasterObj= {
          text: textReceip+
          "\n---------------------------\n" +
          "Total                 "+this.prix_tot+"€\n",
          paperWidth:576,
          fontSize:35,  
          cutReceipt:true,
          openCashDrawer:false
        };
        return threeInchRasterImage ;
  
}
}
