export class User {
  id: number;
  nom: string;
  prenom: string;
  date_de_naissance: Date;
  image: string;
  nomimage: string;
  email: string;
  numero_de_telephone: number;
  premium: boolean;
  statut: boolean;
  number_of_ban: number;
  banned_at: Date;
  email_verified_at: Date;
  is_verified: boolean;
  verification_code: string;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
}
