import { Injectable } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';

@Injectable()
export class AlertService {

  constructor(private loadingCtrl: LoadingController, private alertCtrl: AlertController) { }

  
  async createAlert(error:string, message?:string){
      console.log('create alert');
      let subTitle = message?message:"Something went wrong...";
        const customAlert = this.alertCtrl.create({
        header : message,
        message: message,
        buttons: ['Ok']
        });    
        return (await customAlert).present();
    }

    createLoading(loadingText: string){
        return this.loadingCtrl.create({
          });
    }
    
}