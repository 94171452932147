export class Ingredient {
  id: number;
  nom: string;
  quantite: number;
  prix: number;
  statut: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}
