import { Component, OnInit } from '@angular/core';
import {NavController, NavParams, AlertController } from '@ionic/angular';
import { PrinterService } from "../../Services/PrinterService/printer.service";
import { AlertService } from '../../Services/PrinterService/alert.service';
import { Printers } from '@ionic-native/star-prnt';
import { ReceiptService } from '../../Services/PrinterService/receipt.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-printer-config',
  templateUrl: './printer-config.component.html',
  styleUrls: ['./printer-config.component.css']
})

export class PrinterConfigComponent {
  portType: string;
  printerList: Printers = [];
  selectedPrinter: any = {};
  
  constructor(public navCtrl: NavController, public navParams: NavParams, public alertCtrl: AlertController,
     private printerService:PrinterService, private rs : ReceiptService, private alertService: AlertService,
     private router: Router) {
    this.portType = navParams.get('portType');

    if(this.portType != null){
      this.portDiscovery(this.portType);
    }else{
      this.portDiscovery('All');
    }  
  }

 async  portDiscovery(portType: string){
    
    this.printerService.portDiscovery(portType)
    .then(Printers =>{
      
      this.printerList = [];
      this.printerList = Printers;
      console.log(this.printerList);
    })
    .catch(error =>{
     
      alert('Error finding printers ' + error);
    });
  }

  public selected(){
    console.log("ok");
    let alert = this.alertCtrl.create({
      header: 'Radio',
      inputs: [
        { name : "mPOP",type: 'radio', label: 'mPOP', value: 'StarPRNT' },
        { name : "FVP10",type: 'radio', label: 'FVP10', value: 'StarLine' },
        { name : "TSP100",type: 'radio', label: 'TSP100', value: 'StarGraphic' },
        { name : "TSP650II",type: 'radio', label: 'TSP650II', value: 'StarLine' },
        { name : "TSP650II",type: 'radio', label: 'TSP650II', value: 'StarLine' },
        { name : "TSP700II",type: 'radio', label: 'TSP700II', value: 'StarLine' },
        { name : "TSP800II",type: 'radio', label: 'TSP800II', value: 'StarLine' },
        { name : "SP700",type: 'radio', label: 'SP700', value: 'StarDotImpact' },
        { name : "SM-S210i",type: 'radio', label: 'SM-S210i', value: 'EscPosMobile' },
        { name : "SM-S220i",type: 'radio', label: 'SM-S220i', value: 'EscPosMobile' },
        { name : "SM-S230i",type: 'radio', label: 'SM-S230i', value: 'EscPosMobile' },
        { name : "SM-T300i/T300",type: 'radio', label: 'SM-T300i/T300', value: 'EscPosMobile' },
        { name : "SM-T400i",type: 'radio', label: 'SM-T400i', value: 'EscPosMobile' },
        { name : "SM-L200",type: 'radio', label: 'SM-L200', value: 'StarPRNT' },
        { name : "SM-L300",type: 'radio', label: 'SM-L300', value: 'StarPRNT' },
        { name : "BSC10",type: 'radio', label: 'BSC10', value: 'EscPos' },
        { name : "SM-S210i StarPRNT",type: 'radio', label: 'SM-S210i StarPRNT', value: 'StarPRNT' },
        { name : "SM-S220i StarPRNT",type: 'radio', label: 'SM-S220i StarPRNT', value: 'StarPRNT' },
        { name : "SM-S230i StarPRNT",type: 'radio', label: 'SM-S230i StarPRNT', value: 'StarPRNT' },
        { name : "SM-T300i/T300 StarPRNT",type: 'radio', label: 'SM-T300i/T300 StarPRNT', value: 'StarPRNT' },
        { name : "SM-T400i StarPRNT",type: 'radio', label: 'SM-T400i StarPRNT', value: 'StarPRNT' }
      ],
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Ok',
          handler: emulation => {
            this.savePrinter(emulation);
          }
        }
      ]
    }).then(res => {

      res.present();

    });
  }
 
  savePrinter(emulation){
    if(this.selectedPrinter.printer){
    this.printerService.saveDefaultPrinter(this.selectedPrinter.printer, emulation);
    this.navCtrl.pop();
    this.rs.rasterReceiptExample('3');
    }else{
      alert('Please select the printer ');
    }    
  }

  public pt(){
    this.router.navigate(['pt']);
  }

}
