import { Component, OnInit } from '@angular/core';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { PrinterService } from "../../Services/PrinterService/printer.service";
import { ReceiptService } from '../../Services/PrinterService/receipt.service';
import { AlertService } from '../../Services/PrinterService/alert.service';
import { PrintObj, ImageObj, CommandsArray, RasterObj, CutPaperAction } from '@ionic-native/star-prnt';

@Component({
  selector: 'app-print-test',
  templateUrl: './print-test.component.html',
  styleUrls: ['./print-test.component.css']
})
export class PrintTestComponent implements OnInit {
  defaultPrinter:any;

  constructor(public navCtrl: NavController, public alertCtrl: AlertController,
     private printerService:PrinterService, public modalCtrl: ModalController,
    private alertService: AlertService,  private receiptService: ReceiptService) {  
      this.ionViewDidEnter() ;

    }

    selectPaperSize():Promise<any>{
      return new Promise((resolve, reject) => {
        let alert = this.alertCtrl.create({
          header: 'Select Paper Size',
          inputs: [
            {name:'2', type: 'radio', label: '2" (384dots)', value: '2', checked: true },
            {name:'3',type: 'radio', label: '3" (576dots)', value: '3' },
            {name:'4',type: 'radio', label: '4" (832dots)', value: '4' }
          ],
          buttons: [
            {
              text: 'Cancel',
            },
            {
              text: 'Ok',
              handler: paperSize => {
                alert.then(res => {
  
                  res.dismiss().then(() => { resolve(paperSize); });
                  return false;
            
                })
              }
            }
          ]
        });
        
       
        alert.then(res => {

          res.present();
    
        });
      });
    }

    print(){
      if(this.defaultPrinter){
  
        this.selectPaperSize().then(paperSize => {
          let commands:CommandsArray = this.receiptService.getExampleReceipt('2', false, false);    
          
          let loading = this.alertService.createLoading("Communicating...");
          console.log("1");
          loading.then(res => {

            res.present();
      
          });  
      
          this.printerService.print(this.defaultPrinter.portName, this.defaultPrinter.emulation, commands)
            .then(result => {
              loading.then(res => {

                res.dismiss();
          
              });
              console.log("2");
              this.alertService.createAlert("Success!", "Communication Result: ") })
            .catch(error => {
              console.log("3");

              loading.then(res => {

                res.dismiss();
          
              });
              this.alertService.createAlert(error) 
            })
          })
        }else{
        this.alertService.createAlert("Please select a printer!");
        console.log("4");

      }
    }

    printRasterReceipt(){
      if(this.defaultPrinter){
      this.selectPaperSize().then(paperSize => {
        let rasterObj:RasterObj = this.receiptService.rasterReceiptExample(paperSize);
  
          let loading = this.alertService.createLoading("Communicating...");
  
          this.printerService.printRasterReceipt(this.defaultPrinter.portName, this.defaultPrinter.emulation, rasterObj)
            .then(result => {
              this.alertService.createAlert("Success!", "Communication Result: ") })
            .catch(error => {
              this.alertService.createAlert(error) })
        })   
        }else{
          this.alertService.createAlert("Please select a printer!");
        }
    }

    ionViewDidEnter() {
      this.printerService.getDefaultPrinter()
      .then(printer => {
        console.log(printer);
        this.defaultPrinter = printer});
    }

    printRawText(){
      if(this.defaultPrinter){
     
  
      let printObj:PrintObj = {
        text:"Star Clothing Boutique\n123 Star Road\nCity, State 12345\n\n",
        cutReceipt:true,
        openCashDrawer:false
      }
  
      this.printerService.printRasterReceipt(this.defaultPrinter.portName, this.defaultPrinter.emulation, printObj)
        .then(result => {
       
        this.alertService.createAlert("Success!", "Communication Result: ") })
        .catch(error => {
        
          this.alertService.createAlert(error) })
        }else{
          this.alertService.createAlert("Please select a printer!");
        }
    }
    
  ngOnInit(): void {
  }

}
