export class Env {

  static url:string='https://api.snacklespyramides.fr';
  static firebase = {
  apiKey: "AIzaSyBJEWVqgrRLxE9wv3vS1JsS6iQ4SeQdELs",
  authDomain: "lespyramides-7203e.firebaseapp.com",
  projectId: "lespyramides-7203e",
  storageBucket: "lespyramides-7203e.appspot.com",
  messagingSenderId: "721261364231",
  appId: "1:721261364231:web:9e3af2efc0138dcf8556a2"


  };
}
